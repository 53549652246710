import { ApiCollection }               from 'Collections/ApiCollection';
import ActivityCategoryModel           from 'Models/sales/ActivityCategoryModel';
import PurchaseOrderTargetModel        from 'Models/sales/PurchaseOrderTargetModel';
import {
	QUOTATION_STATUS_ACTIVATED,
	QUOTATION_STATUS_BLOCKED,
	QUOTATION_STATUS_SENT_TO_CLIENT,
}                                      from 'constants/QuotationStatuses';
import { computed }                    from 'mobx';
import TaskZoneModel                   from '../modelx/models/private/intervention/TaskZoneModel';
import QuotationModel                  from '../modelx/models/private/sales/QuotationModel';
import { appStore }                    from '.';

export default class QuotationsStore implements Store {

	public activityCategoryCollection = new ApiCollection(ActivityCategoryModel);
	public quotationCollection = new ApiCollection(QuotationModel);
	
	private _defaultStatusReferences = [
		QUOTATION_STATUS_ACTIVATED.reference,
		QUOTATION_STATUS_BLOCKED.reference,
		QUOTATION_STATUS_SENT_TO_CLIENT.reference,
	];
	private _purchaseOrderQuotationUrns: string[] | undefined;
	private _quotationStatusUrns: string[] = [];

	private _taskZoneUrn = '';

	public clear() {
		this.quotationCollection.clear();
	}

	public async fetch(): Promise<void> {
		this.quotationCollection.setFilters({
			archived: false,
			id: this._purchaseOrderQuotationUrns,
			'items.itemActivities.locationUrn': this._taskZoneUrn,
			'quotationStatus.reference': this._quotationStatusUrns.length === 0 ? this._defaultStatusReferences : (
				appStore.quotationStatusCollection
					.filter(value => this._quotationStatusUrns.includes(value.urn))
					.map(value => value.reference)
			),
		});
		await Promise.all([
			this.activityCategoryCollection.list({ cache: 3600, }),
			this.quotationCollection.list(),
		]);
	}

	public setPurchaseOrderUrnFilterAndFetch = async (urn?: Urn) => {
		if (urn) {
			const purchaseOrderTargetCollection = new ApiCollection(PurchaseOrderTargetModel);
			await purchaseOrderTargetCollection.listBy([urn], 'purchaseOrder');
			this._purchaseOrderQuotationUrns = purchaseOrderTargetCollection.map((target) => target.targetUrn);
			this.fetch();
		}
		else {
			this._purchaseOrderQuotationUrns = undefined;
			this.fetch();
		}
	};

	public setQuotationStatusUrnFilterAndFetch = (urns: string[]) => {
		this._quotationStatusUrns = urns;
		this.fetch();
	};

	public setTaskZoneFilterAndFetch = (taskZone: TaskZoneModel) => {
		this._taskZoneUrn = taskZone.urn;
		this.fetch();
	};

	@computed
	public get isLoading(): boolean {
		return (
			this.quotationCollection.isLoading
		);
	}
}
